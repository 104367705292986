@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body,
#__next,
#__next > div {
  height: 100vh;
  width: 100vw;
}

html {
  background-color: rgb(248 249 254);
}

.modal-overlay {
  background-color: rgba(56, 56, 56, 0.5);
}

.EmojiPickerReact {
  position: absolute !important;
}

.react-flow__edgelabel-renderer,
.react-flow__handle-right {
  z-index: 10004;
}

/*
 Dynamic input (Tip Tap editor) related CSS
*/

/* Basic editor styles */
.tiptap {
  padding: 5px 8px;
  border-radius: 8px;
  width: 100%;
  max-height: 50vh;
  font-size: 14px;
  color: #1f2024;
  line-height: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 2px;
  
  @apply outline-none ring-inset focus:ring-HighlightMedium hover:ring-HighlightMedium;
}

.tiptap.base-state{
  @apply ring-NeutralLightDarkest ring-1;
}

.tiptap.error-state{
  @apply ring-red-500 ring-1
}

.tiptap.height-small {
  padding: 2px 12px;
}

.tiptap[contenteditable='false'] {
  @apply text-NeutralLightDarkest bg-NeutralLightLight hover:ring-NeutralLightDarkest hover:ring-1;
}

.tiptap > p {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tiptap > p.is-empty {
  display: block;
}

.tiptap > p > span {
  margin-top: auto;
  margin-bottom: auto;
}

/* Editor Placeholder style */
.tiptap p.is-editor-empty:first-child::before {
  color: #8f9098;
  font-size: 14px;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
